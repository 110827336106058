import { ICity, ICountry, IProvince, IRegion } from '../common/country';
import { ICurrency } from '../common/currency';
import { IdName, idNames } from '../common/id-name';
import { IDelivery, IGeoInfo, IPermissions, IWallet } from './account';
import { ISelfLimitation } from './autoexclusion';
import { IDocument } from './documents';
import { Tfa } from './tfa';

export interface IMe extends BaseAccount {
  realityCheckTime?: number;
  permissions: IPermissions;
  documents?: Array<IDocument>;
  idleTimeout?: number;
  limitations?: ISelfLimitation;
  tfa?: Tfa;
}

interface BaseAccount {
  bookmakerId: number;
  id: number;
  username: string;
  typeId: number; // AccountTypeId | NetworkTypes;
  status: IdName<string>;
  passwordExpired: boolean;
  kyc: boolean;
  currencies: Array<ICurrency>;
  wallets: Array<IWallet>;
  deliveries: Array<IDelivery>;
  person?: IPerson;
  geoInfos?: Array<IGeoInfo>;
  tpl?: string;
}

export interface IAccount extends BaseAccount {
  parent: IAccountParent;
  //bookmakerId: number;
  registrationDate: Date;
}

/**
 * @deprecated
 */
export enum AccountTypeId {
  Player = 0,
  Operator = 10,
  Shop = 20,
  Cashier = 21,
  Agent = 30,
  WebAgent = 31,
  Bookmaker = 200
}

export namespace AccountTypeId {
  export function values(): Array<IdName<any>> {
    return idNames(AccountTypeId);
  }
}

export enum AccountStateId {
  Enabled = 1,
  Disabled,
  // Deleted, // [MONO-1029]
  BeValidated = 4,
  Frozen,
  //Error,
  //ReadOnly,
  RegistrationPending = 8
}

export namespace AccountStateId {
  export function values(): Array<IdName<any>> {
    return idNames(AccountStateId);
  }
}

export class IAccountParent {
  dateFrom: string;
  id: number;
  typeId: AccountTypeId;
  username: string;
}

export interface IPerson {
  id?: number;
  firstName: string;
  lastName: string;
  genderTypeId: number;
  birthDate: string;
  birthPlace?: IPersonBirthPlace;
}

export interface IPersonBirthPlace {
  country: ICountry;
  region: IRegion;
  city?: ICity;
  province?: IProvince;
}

export interface CheckToken {
  lastUpdate: Date;
  isClosed: boolean;
}

export enum GenderTypes {
  Male = 1,
  Woman = 2,
  // Transgender,
  // NonBinaryNonConforming,
  // PreferNoToRespond
}

export namespace GenderTypes {
  export function values(): Array<IdName<any>> {
    return idNames(GenderTypes);
  }
}
